import { Navigate, createBrowserRouter } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import BlockchainStats from '../pages/BlockchainStats'
import Musharakah from '../pages/Musharakah'
import Notifications from '../pages/Notifications'
import RealEstateObjects from '../pages/RealEstateObjects'
import Settings from '../pages/Settings'
import StablecoinsStats from '../pages/StablecoinStats'
import TokenSales from '../pages/TokenSales'
import TokenSaleRequests from '../pages/TokenSaleRequests'
import Transactions from '../pages/Transactions'
import Admins from '../pages/Admins'
import Users from '../pages/Users'
import Layout from '../components/Layout'
import NewTokenSale from '../pages/NewTokenSale'
import User from '../pages/User'
import TokenSale from '../pages/TokenSale'
import LoginForm from '../pages/LoginForm'
import ProtectedRoute from './ProtectedRoute'
import NewAdmin from '../pages/NewAdmin'
import Admin from '../pages/Admin'
import EditAdmin from '../pages/EditAdmin'
import TokenSaleRequest from 'pages/TokenSaleRequest'
import UserService from 'api/UsersService'
import TokenSaleService from 'api/TokenSaleService'
import FundedTokenSales from 'pages/FundedTokenSales'
import PaidOffTokenSales from 'pages/PaidOffTokenSales'

export default createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to='/dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admins',
        element: (
          <ProtectedRoute>
            <Admins />
          </ProtectedRoute>
        ),
      },
      {
        path: 'new-admin',
        element: (
          <ProtectedRoute>
            <NewAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/:id',
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'edit-admin/:id',
        element: (
          <ProtectedRoute>
            <EditAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        ),
      },
      {
        path: 'user/:id',
        loader: async ({ params }) => {
          if (!params.id) {
            throw new Response('Not Found', { status: 404 })
          }
          try {
            const [user, tokenSalesStatistics, tokenAssets, transactions] =
              await Promise.all([
                UserService.getUser(params.id),
                UserService.fetchTokenSalesStatistics(params.id),
                UserService.fetchTokenAssets(params.id),
                UserService.fetchTransactions(params.id),
              ])
            return { user, tokenSalesStatistics, tokenAssets, transactions }
          } catch (error) {
            throw new Response('Not Found', { status: 404 })
          }
        },
        element: (
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        ),
      },
      {
        path: 'blockchain-stats',
        children: [
          {
            index: true,
            element: <BlockchainStats />,
          },
        ],
      },
      {
        path: 'musharakah',
        children: [
          {
            index: true,
            element: <Musharakah />,
          },
        ],
      },
      {
        path: 'notifications',
        children: [
          {
            index: true,
            element: <Notifications />,
          },
        ],
      },
      {
        path: 'real-estate-objects',
        children: [
          {
            index: true,
            element: <RealEstateObjects />,
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            index: true,
            element: <Settings />,
          },
        ],
      },
      {
        path: 'stablecoin-stats',
        children: [
          {
            index: true,
            element: <StablecoinsStats />,
          },
        ],
      },
      {
        path: 'tokensales',
        element: (
          <ProtectedRoute>
            <TokenSales />
          </ProtectedRoute>
        ),
      },
      {
        path: 'tokensale/:id',
        loader: async ({ params }) => {
          if (!params.id) {
            throw new Response('Not Found', { status: 404 })
          }
          try {
            const [tokenSale, investors, transactions] = await Promise.all([
              TokenSaleService.getTokenSale(params.id),
              TokenSaleService.fetchInvestors(params.id),
              TokenSaleService.fetchTransactions(params.id),
            ])
            return { tokenSale, investors, transactions }
          } catch (error) {
            throw new Response('Not Found', { status: 404 })
          }
        },
        element: (
          <ProtectedRoute>
            <TokenSale />
          </ProtectedRoute>
        ),
      },
      {
        path: 'tokensale-requests',
        element: (
          <ProtectedRoute>
            <TokenSaleRequests />
          </ProtectedRoute>
        ),
      },
      {
        path: 'tokensale-request/:id',
        element: (
          <ProtectedRoute>
            <TokenSaleRequest />
          </ProtectedRoute>
        ),
      },
      {
        path: 'completed-tokensales',
        element: (
          <ProtectedRoute>
            <FundedTokenSales />
          </ProtectedRoute>
        ),
      },
      {
        path: 'paidoff-tokensales',
        element: (
          <ProtectedRoute>
            <PaidOffTokenSales />
          </ProtectedRoute>
        ),
      },
      {
        path: 'transactions',
        children: [
          {
            index: true,
            element: <Transactions />,
          },
        ],
      },
      {
        path: 'new-token-sale',
        element: (
          <ProtectedRoute>
            <NewTokenSale />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/login',
    children: [
      {
        index: true,
        element: <LoginForm />,
      },
    ],
  },
])
