import styles from './styles.module.scss'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTokenSaleRequest } from '../../hooks/useTokenSales'
import { useNavigate } from 'react-router-dom'

import { formatNative } from 'utils/formatNumber'
import ModalUi from 'components/ModalUi'
import TokenSaleService from 'api/TokenSaleService'
import GlobalPreloader from 'components/GlobalPreloader'

const TokenSaleRequest = () => {
  const { id } = useParams()
  const { tokenSaleRequest, isLoading, error } = useTokenSaleRequest(id)

  const navigate = useNavigate()

  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)
  const [isApprovalModalLoading, setIsApprovalModalLoading] = useState(false)
  const [approvalModalError, setApprovalModalError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formState, setFormState] = useState({
    tokenTotalSupply: '',
    houseSalePrice: '',
    saleMaxBuy: '',
    rentAmount: '',
    annualOperatingExpenses: '',
    projectedAnnualReturn: '',
    projectedRentalYield: '',
    rentalYield: '',
    addFee: '',
    feePercentage: '',
    initialReservesPercentage: '',
    reservesTotalTargetAmountPercentage: '',
    feesWalletAddress: '',
    taxWalletAddress: '',
    insuranceWalletAddress: '',
    maintanceReservesWalletAddress: '',
    tokenSaleBeneficiary: '',
  })

  const openApprovalModal = () => setIsApprovalModalOpen(true)
  const closeApprovalModal = () => setIsApprovalModalOpen(false)

  if (isLoading) return <GlobalPreloader />
  if (error) return <div>
    Error:
    {' '}
    {error}
  </div>

  // const calculateTimeLeft = (endDate) => {
  //   const today = new Date()
  //   const end = new Date(endDate)
  //   const timeLeft = end - today
  //   const daysLeft = Math.round(timeLeft / (1000 * 60 * 60 * 24))
  //   return daysLeft > 0 ? `${daysLeft} days` : '0 days'
  // }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormState({ ...formState, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      // console.log('formState.projectedAnnualReturn;' + formState.projectedAnnualReturn)
      await TokenSaleService.updateTokenSale(id, formState)
      closeModal()
      window.location.reload()
    } catch (error) {
      console.error('Failed to update token sale', error)
    }
  }

  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', options)
  }

  function formatString(str) {
    return str
      .split('_') // Разбиваем строку на массив слов
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Делаем первую букву каждого слова заглавной
      .join(' ') // Объединяем слова обратно в строку с пробелами
  }

  function getFileNameFromUrl(url) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  const approveTokenSale = async (event) => {
    event.preventDefault()

    setIsApprovalModalLoading(true)
    setApprovalModalError('')

    try {
      await TokenSaleService.approveTokenSaleRequest(id)
      console.log('success')
      setTimeout(() => {
        closeApprovalModal()
        navigate('/tokensale-requests')
      }, '5000')
    } catch (error) {
      setApprovalModalError(error.response?.data?.message || 'Failed to approve tokensale.')
    }
  }
  console.log('tokenSaleRequest: ',tokenSaleRequest)
  return (
    <>
      <h2>
        TokenSale request #
        {tokenSaleRequest.id}
      </h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Tokensale request main info</p>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Public title</p>
              <p className={styles.innerDesc}>
                {' '}
                {tokenSaleRequest.title}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.address.address}, ${tokenSaleRequest.address.city}, ${tokenSaleRequest.address.country}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>About the Property</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.description}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Annual Return</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.projected_annual_return}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.projected_rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Charge Fee</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.edificex_fee.add_fee}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Fee Percentage</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.edificex_fee.fee_percentage}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Initial Reserves Percentage</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.initial_reserves_percentage}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Initial Reserves Amount</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.initial_reserves_amount} DNRs`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Total Target Reserves Percentage</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.reserves_total_target_amount_percentage}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Total Target Reserves Amount</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.reserves_total_target_amount} DNRs`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Beneficiary Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.token_info.beneficiary_address}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Fee Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.edificex_fee.fees_wallet_address}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Reserves Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.expenses_address.property_maintenance_reserve_address}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Tax Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.expenses_address.property_tax_address}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Insurance Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.expenses_address.property_insurance_address}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Thumbnail</p>
              <p className={styles.innerDesc}><img className={styles.imgWrapper} src={tokenSaleRequest.thumbnail?.url} alt="" /></p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Images</p>
              <div className={styles.innerImages}>
                {
                  tokenSaleRequest.images?.map((image) => (
                    <div key={image.url}>
                      <p className={styles.innerDesc}><img className={styles.imgWrapper} src={image.url} alt="" /></p>
                    </div>
                  ))
                }
              </div>

            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Documents</p>
              <div className={styles.innerImages}>
                {
                  tokenSaleRequest.documents.map((doc) => (
                    <div key={doc.url}>
                      <p className={styles.innerDesc}><a className={styles.innerDoc} href={doc.url}>{getFileNameFromUrl(doc.url)}</a></p>
                    </div>
                  ))
                }
              </div>

            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <div>
              <div className={styles.title}>ID</div>
              <div className={styles.desc}>{tokenSaleRequest.id}</div>
            </div>
            <div>
              <div className={styles.title}>Total tokens</div>
              <div className={styles.desc}>{`${formatNative(tokenSaleRequest.token_info.hardcap)}`}</div>
            </div>
            <div>
              <div className={styles.title}>Price per token</div>
              <div className={styles.desc}>
                {`${formatNative(tokenSaleRequest.token_info.price)} DNR`}
              </div>
            </div>
            <div>
              <div className={styles.title}>House Price</div>
              <div className={styles.desc}>
                {`${formatNative(tokenSaleRequest.house_sale_price)} DNR`}
              </div>
            </div>
            {/* <div>
              <div className={styles.title}>Time left</div>
              <div className={styles.desc}>
                {calculateTimeLeft(tokenSaleRequest.token_info.sale_end_at)}
              </div>
            </div> */}
            <div>
              <div className={styles.title}>Tokensale start at</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.token_info.sale_start_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Tokensale end at</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.token_info.sale_end_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Creation date</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.created_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Status</div>
              <div className={styles.desc}>
                {formatString(tokenSaleRequest.status)}
              </div>
            </div>
          </div>
          <div><strong>Update Fee and Expese Addresses First</strong></div>
          <div
            className={`${styles.submitBtn} ${!tokenSaleRequest.edificex_fee.fees_wallet_address ? styles.disabled : ''}`}
            onClick={() => {
              if (tokenSaleRequest.edificex_fee.fees_wallet_address) {
                openApprovalModal()
              }
            }}
          >
            Approve Token Sale
          </div>
          <div className={styles.actionBtn0} onClick={openModal}>Update Token Sale</div>
          <ModalUi isOpen={isModalOpen} onClose={closeModal}>
            <div className={styles.modalWrapper1}>
              <h4 className={styles.modalHeader}>Update Token Sale</h4>
              <div className={styles.modalContent1}>
                <form style={{ textAlign: 'center' }} onSubmit={handleSubmit}>
                  <br />
                  <input className={styles.inputField} type="text" name="tokenTotalSupply" value={formState.tokenTotalSupply} onChange={handleChange} placeholder="Token Total Supply" />
                  {/* <br />
                  <input className={styles.inputField} type="text" name="houseSalePrice" value={formState.houseSalePrice} onChange={handleChange} placeholder="Sale Price" /> */}
                  <br />
                  <input className={styles.inputField} type="text" name="rentAmount" value={formState.rentAmount} onChange={handleChange} placeholder="Rent Amount" />
                  <br />
                  <input className={styles.inputField} type="text" name="annualOperatingExpenses" value={formState.annualOperatingExpenses} onChange={handleChange} placeholder="Annual Operating Expenses" />
                  <br />
                  <input className={styles.inputField} type="text" name="addFee" value={formState.addFee} onChange={handleChange} placeholder="true or false" />
                  <br />
                  <input className={styles.inputField} type="text" name="feePercentage" value={formState.feePercentage} onChange={handleChange} placeholder="Transaction Fee Percentage" />
                  <br />
                  <input className={styles.inputField} type="text" name="initialReservesPercentage" value={formState.initialReservesPercentage} onChange={handleChange} placeholder="Initial Reserve Percentage" />
                  <br />
                  <input className={styles.inputField} type="text" name="reservesTotalTargetAmountPercentage" value={formState.reservesTotalTargetAmountPercentage} onChange={handleChange} placeholder="Total Reserve Amount Percentage" />
                  <br />
                  <input className={styles.inputField} type="text" name="feesWalletAddress" value={formState.feesWalletAddress} onChange={handleChange} placeholder="Fee Wallet Address" />
                  <br />
                  <input className={styles.inputField} type="text" name="taxWalletAddress" value={formState.taxWalletAddress} onChange={handleChange} placeholder="Tax Wallet Address" />
                  <br />
                  <input className={styles.inputField} type="text" name="insuranceWalletAddress" value={formState.insuranceWalletAddress} onChange={handleChange} placeholder="Insurance Wallet Address" />
                  <br />
                  <input className={styles.inputField} type="text" name="maintanceReservesWalletAddress" value={formState.maintanceReservesWalletAddress} onChange={handleChange} placeholder="Reserves Wallet Address" />
                  <br />
                  <input className={styles.inputField} type="text" name="tokenSaleBeneficiary" value={formState.tokenSaleBeneficiary} onChange={handleChange} placeholder="TokenSale Beneficiary Address" />
                  <br />
                  <button className={styles.inputField} type="submit">Update</button>
                </form>
              </div>
            </div>
          </ModalUi>
          <div className={styles.actionBtn1}>Write to the sender</div>
        </div>
      </div>
      <ModalUi isOpen={isApprovalModalOpen} onClose={closeApprovalModal}>
        <div className={styles.modalWrapper}>
          <h4 className={styles.modalHeader}>
            Are you sure you want to approve the Token Sale?
          </h4>
          {/* <p className={styles.modalDescription}>
            This action will be irreversible and all data will be deleted
            forever
          </p> */}
          <div className={styles.modalActions}>
            {approvalModalError && (
              <div className={styles.errorWrapper}>{approvalModalError}</div>
            )}
            <button onClick={approveTokenSale} className={styles.modalAccept}>
              {isApprovalModalLoading
                ? 'Approving...'
                : 'Yes I want to approve'}
            </button>
            <button onClick={closeApprovalModal} className={styles.modalDecline}>
              Cancel
            </button>
          </div>
        </div>
      </ModalUi>
    </>
  )
}

export default TokenSaleRequest
